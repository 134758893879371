import axios from "@/config/axios";

export default {
    sendMail: function (data) {
        return axios({
            url: "/api/order-art/otp",
            method: 'post',
            data: data
        });
    },
    submitOtp: function (data) {
        return axios({
            url: "/api/order-art/check-otp",
            method: 'post',
            data: data
        });
    }
}
