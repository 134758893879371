<template>
  <div id="otp" style="margin-top: 10vh;"> 
    <div class="otp-container height-100 d-flex justify-content-center align-items-center"> 
        <div class="position-relative"> 
            <div class="otp-card p-2 text-center">
                 <h6 style="margin: 20px;"> {{ $t('TEXT0320') }}<br></h6>
                <div class="inputs">
                    <div v-for="(otp, i) in otps" :key="i">
                        <input :id="'otp-'+i"
                        @paste="handleClipboardChange"
                        autofocus
                        type="number"
                        onkeypress="if (isNaN( String.fromCharCode(event.keyCode) )) return false;"
                        @keyup.delete="ondelete(i)" 
                        class="otp-input"
                        maxlength="1" 

                        @input="onchange(i)" 
                        v-model="otps[i]"
                        oninput=" if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </div>
                </div>
                <div style="margin-top: 16px;"> 
                    <button @click="submit()" class="px-4 validate">{{ $t('TEXT0321') }}</button> 
                </div>  
                <div class="resend mt-3 content d-flex justify-content-center align-items-center"> 
                    <span>{{ $t('TEXT0322') }}</span> 
                    <button :disabled="timerEnabled" @click="resend" class="resend-btn" >{{ $t('TEXT0323') }}</button>
                    <p v-if="timerEnabled"> {{ timerCount  }}s</p> 
                </div> 
            </div> 
        </div>
    </div>
    <ErrorDialog
      v-model="popup.unMatch.show"
      :message="$t('TEXT0312')"
    />
    <ErrorDialog
      v-model="popup.expire.show"
      :message="$t('TEXT0311')"
    />
    <ErrorDialog
      v-model="popup.error.show"
      :message="popup.error.data"
    />
</div>
</template>

<script>
import ErrorDialog from "../components/ErrorDialog.vue"
import OtpApi from "../api/OtpApi"
export default {
    name: "OTPCode",
    components: {
        ErrorDialog,
    },
    watch: {
        clipboardValue: function(newVal) {
            let splitVal = newVal.split("").slice(0, 6);
            this.otps.splice(0, splitVal.length, ...splitVal);
        }
    },
    data() {
        return {

            otps :['', '', '', '', '', ''],
            timerCount: 0,
            timerEnabled: false,
            popup: {
                unMatch: {
                    data: null,
                    show: false
                },
                expire:{
                    data: null,
                    show: false
                },
                error:{
                    data: null,
                    show: false
                }
            }
        }
    },
    created () {
        this.sendOtp();
    },
    beforeUnmount() {
    document.removeEventListener('paste', this.handleClipboardChange);
    },
    methods: {
        handleClipboardChange(evt) {
            let text =  evt.clipboardData.getData("text")
            const splitVal = text.split("").slice(0, 6);
            this.otps.splice(0, splitVal.length, ...splitVal);
        },
        countDownTimer () {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.timerEnabled = false;
            }
        },
        resend() {
            this.sendOtp();
        },

        stopResend() {
            this.timerEnabled = false;
        },
        onchange(i) {
            if (this.otps[i] && i < 5) {
                   document.querySelector('#otp-' + (i + 1)).focus()
                } 
            
        },
        ondelete(i) {
            if (!this.otps[i] &&  i > 0) {
                document.querySelector('#otp-' + (i - 1)).focus()
            }
        },
        async submit(){
            let req= {
                token : this.$route.query.token,
                otpCode : this.otps.join('')
            }
            await OtpApi.submitOtp(req).then((resp)=>{
                if(resp.status === 200){
                    localStorage.setItem("token", resp.data.token);
                    this.$router.push('/kanji/convert/certificate')
                } else if( resp.status === 412){
                    this.popup.error.data = resp.data[0].value |'error'
                    this.popup.error.show = true
                }
            }).catch((err) => {
                this.popup.error.data = err.data ? err.data[0].value : 'error'
                this.popup.error.show = true
                
            }).finally(()=>{

            })
        },
        async sendOtp(){
            let req= {
                token : this.$route.query.token,
            }
            await OtpApi.sendMail(req).then((resp)=>{
                console.log(resp);
            }).catch(err => console.log('sendmailotp', err)
            ).finally(()=>{
                this.timerEnabled = true;
                this.timerCount = 60
                this.countDownTimer()
            })
            
        }
    },
}
</script>

<style scoped>
    #otp{
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .otp-container{
        height: 100px;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    body{
        background-color: #44bcff;
    }
    .height-100{
        height: 100vh
    }
    .otp-card{
        width: 400px;
        border: none;
        height: 300px;
        box-shadow: 0px 5px 20px 0px #a0939f;
        z-index: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 15px;
        background-color: #fff;
        border-radius: 9px;
    }
    .otp-card > h6 {
        text-align: center;
    }
    @media screen and (max-width: 500px) {
        .otp-card{
            width: 80vw;
            margin: auto;
            border: none;
            height: 300px;
            box-shadow: 0px 5px 20px 0px #a0939f;
            z-index: 1;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            padding: 15px;
            background-color: #fff;
        }
        .otp-input{
            width: 32px !important;
            height: 32px !important;
        }
    }
    @media screen and (max-width: 350px) {
        .otp-input{
        text-align: center;
        padding: 2px !important;
        margin: 2px !important;
    }
    }
    .otp-input{
        text-align: center;
        padding: 4px;
        margin: 4px;
    }
    .otp-card h6{
        color: #2899f3;
        font-size: 20px;
        
    }
    .inputs input{
        width: 40px;
        height: 40px
    }
    .inputs{
        justify-content: center;
        display: flex;
        flex-direction: row;
        margin-top: 8px;
    }
    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0
    }
    .form-control:focus{
        box-shadow: none;
        border: 2px solid #44bcff
    }
    .validate{
        border-radius: 20px;
        height: 40px;
        background-color: #44bcff;
        border: 1px solid #44bcff;
        width: 100%;
        padding: 20px 40px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        display: flex;
        font-weight: 600;
        align-items: center;
    }
    
    .validate:hover{
        opacity: 0.8;
        cursor: pointer;
    }
    .position-relative{
        position: relative;
    }
    .content a{
        color:#D64F4F;
        transition: all 0.5s
    }
    .content a:hover{
        color: #44bcff
    }
    .resend{
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    } 
    .resend-btn{
        border: none;
        background-color: transparent;
        color: #2899f3;
    }
    .resend-btn:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    .resend>p {
        margin: 0;
    }
    .resend-btn:disabled {
        color: #a0939f;
        cursor:not-allowed;
    }
</style>